import React from "react";

const Testimonial = () => {
  return (
    <section className="ui-section-testimonial">
      <div className="ui-layout-container">
        <div className="ui-layout-column-6 ui-layout-column-center">
          <p className="ui-section-testimonial--quote ui-text-intro">
            &ldquo;Our goal is to exceed the expectations of the visitor while providing a reliable and efficient
            service to tour wholesalers, inbound tour companies, travel agents and independent travelers alike&rdquo;
          </p>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
