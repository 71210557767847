import React from "react";

const Pricing = () => {
  return (
    <section className="ui-section-pricing" id="our-services">
      <div className="ui-layout-container">
        <h2>Our Services</h2>
      </div>
    </section>
  );
};

export default Pricing;
